import React, { ReactNode } from 'react'
import withWindowDimensions from 'components/common/withWindowDimensions'
import Image from 'next/image'
import { LoginProps, withLogin } from 'components/Login/withLogin'

export enum destinations {
  TELEGRAM = 'TELEGRAM',
  YOUTUBE = 'YOUTUBE',
}
interface Props {
  isMobileSized?: boolean
  content: string
  buttonText: string
  header: string | ReactNode
  hasTwoButtons: boolean
  ctaAction?: (destination: string) => void
  trackingEvent?: () => void
  primaryButtonCtaHandler?: () => void
}

// need to use this only after fetchStage action from redux is dispatched
const ReadyToTakeLeapBanner = ({
  isMobileSized,
  content,
  buttonText,
  header,
  hasTwoButtons,
  handleSpotCounsellingFlow,
  ctaAction,
  trackingEvent,
  primaryButtonCtaHandler,
}: Props & LoginProps) => {
  return (
    <div
      className={`w-11/12 md:max-w-[1200px] md:mx-auto md:w-9/12 rounded-[9px] bg-[#4A46FF] py-32 ${
        hasTwoButtons ? 'md:pb-12 md:pt-10' : 'md:py-8'
      } m-auto relative ${isMobileSized && hasTwoButtons && 'h-[570px]'} ${
        hasTwoButtons && 'md:h-[370px]'
      } my-[32px] md:my-[40px]`}
    >
      <h2
        className={`font-bold text-4xl md:text-5xl text-white text-center m-auto w-5/6 md:w-full ${
          !hasTwoButtons && 'md:pt-8 mt-[16px]'
        }`}
      >
        {header}
      </h2>
      <p
        className={`${
          !hasTwoButtons
            ? 'md:text-xl text-[20px]'
            : 'md:text-[18px] text-[16px]'
        } font-normal text-white w-10/12 md:w-5/12 m-auto text-center md:mt-4 mt-6`}
      >
        {content}
      </p>
      {/* button */}
      {hasTwoButtons ? (
        <div className="md:flex md:w-[450px] md:justify-center md:m-auto md:absolute md:bottom-[40px] right-[30%]">
          <button
            className="py-[7px] px-[7px] flex items-center bg-white font-bold text-[14px] rounded-[10px]  m-auto mt-8 md:mt-4 relative z-10"
            onClick={() => ctaAction && ctaAction(destinations.TELEGRAM)}
          >
            <Image
              src={'/assets/icons/LSRevamp/telegram.svg'}
              width={46}
              height={46}
              alt={'telegram'}
            />
            <p className="w-[123px] mx-2">Join our Telegram community </p>
          </button>
          <button
            onClick={() => {
              if (trackingEvent) trackingEvent()
              if (ctaAction) {
                ctaAction(destinations.YOUTUBE)
              }
            }}
            className="py-[7px] px-[7px] items-center bg-white font-bold text-[14px] rounded-[10px] flex m-auto mt-3 md:mt-4 relative z-10"
          >
            <Image
              src={'/assets/icons/LSRevamp/youtube_1.svg'}
              width={46}
              height={46}
              alt={'youtube-logo'}
            />
            <p className="w-[123px] mx-2">Join our YouTube community </p>
          </button>
        </div>
      ) : (
        <button
          className="py-4 md:py-4 px-8 bg-white text-[#4A46FF] font-bold text-base md:text-normal rounded block m-auto mt-8 md:mt-12 relative z-10"
          onClick={
            primaryButtonCtaHandler
              ? primaryButtonCtaHandler
              : () => {
                  if (handleSpotCounsellingFlow) handleSpotCounsellingFlow()
                  if (trackingEvent) trackingEvent()
                }
          }
        >
          <h2>{buttonText}</h2>
        </button>
      )}
      <div className="absolute z-10 rounded-full top-[16px] md:top-40 left-[28px]  md:left-90">
        <Image
          src="/assets/icons/LSRevamp/guy_image_second.svg"
          alt="personImg"
          width={76}
          height={76}
          className="rounded-full"
        />
      </div>
      <div className="absolute z-10 rounded-full top-[45px] md:top-[178px] left-[145px]">
        <Image
          src="/assets/icons/LSRevamp/young_indian_girl.svg"
          alt="personImg"
          width={54}
          height={54}
          className="rounded-full"
        />
      </div>
      <div className="absolute z-10 top-[45px] md:top-[275px] left-[241px] md:left-[60px]">
        <Image
          src="/assets/icons/LSRevamp/harps_joseph.svg"
          alt="personImg"
          width={66}
          height={66}
          className="rounded-full "
        />
      </div>
      {isMobileSized && hasTwoButtons ? null : (
        <>
          <div className="absolute z-10 w-10 md:w-auto bottom-[55px] md:top-[138px] right-[278px] md:right-[191px]">
            <Image
              src="/assets/icons/LSRevamp/ira_guha.svg"
              alt="personImg"
              width={63}
              height={63}
              className="rounded-full"
            />
          </div>
          <div className="absolute z-10 w-12 md:w-auto bottom-[55px] md:top-50 right-[28px] md:right-[45px]">
            <Image
              src="/assets/icons/LSRevamp/person_ellipse.svg"
              alt="personImg"
              width={88}
              height={88}
              className="rounded-full"
            />
          </div>
          <div className="absolute z-10 w-10 md:w-auto bottom-[28px] md:bottom-50 right-[144px] md:right-[57px]">
            <Image
              src="/assets/icons/LSRevamp/guy_image.svg"
              alt="personImg"
              width={74}
              height={74}
              className="rounded-full"
            />
          </div>
        </>
      )}
      <img
        src="/assets/icons/LSRevamp/vector_first.svg"
        alt="vector"
        className="opacity-40 absolute top-40 md:top-[12px] left-[2px]"
      />
      <img
        src="/assets/icons/LSRevamp/vector_second.svg"
        alt="vector"
        className="opacity-40 absolute top-[123px] md:top-[95px] left-[12px]"
      />
      <img
        src={
          isMobileSized
            ? '/assets/icons/LSRevamp/vector_first_2.svg'
            : '/assets/icons/LSRevamp/vector_first_1.svg'
        }
        alt="vector"
        className="opacity-40 absolute bottom-5 md:top-[2px] right-[20px] md:right-[2px]"
      />
      <img
        src={
          isMobileSized
            ? '/assets/icons/LSRevamp/vector_second_1.svg'
            : '/assets/icons/LSRevamp/vector_second_2.svg'
        }
        alt="vector"
        className="opacity-[25%] absolute bottom-[78px] md:top-[60px] right-[25px] md:right-9"
      />
    </div>
  )
}

export default withWindowDimensions(withLogin(ReadyToTakeLeapBanner))
